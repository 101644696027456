import { Container } from "react-bootstrap";
import "./style.css";

const AboutSec = () => {
    return (
        <div className="main-about-div">
            <Container className="about-container">
                <div className="about-div">
                    <h1 className="mb-5">About Us</h1>
                    <p>
                        Founded by Hajira Taimur with Hamza Bokhari as the Creative Director, JEEM has come a long way in gathering critical acclaim from the fashion pundits of the country; catering to the urban, elegant and contemporary woman.
                    </p>
                </div>
            </Container>
        </div>
    );
};

export default AboutSec;