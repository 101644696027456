import RoutesPages from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserContextProvider from './Context/User/userContextProvider.js';

function App() {
  return (
    <UserContextProvider>
      <RoutesPages />
    </UserContextProvider>
  );
}

export default App;
