import { Container } from "react-bootstrap";
import "./style.css";
import { FaFacebookF, FaInstagram, FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { Tooltip } from 'antd';
// import Button from "../../Components/Button/index.jsx";
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Footer = () => {
    // const companyLinks = [
    //     {
    //         name: "Contact us",
    //         link: "/contactUs"
    //     },
    //     {
    //         name: "Shipping Information",
    //         link: "/policies/shippingPolicy"
    //     },
    //     {
    //         name: "Return & Exchange",
    //         link: "/policies/returnAndExchangePolicy"
    //     },
    //     {
    //         name: "Refund Policy",
    //         link: "/policies/refundPolicy"
    //     },
    // ];

    // const helpLinks = [
    //     {
    //         name: "About us",
    //         link: "/policies/aboutUs"
    //     },
    //     {
    //         name: "Privacy Policy",
    //         link: "/policies/privacyPolicy"
    //     },
    //     {
    //         name: "Terms And Conditions",
    //         link: "/policies/termsAndConditions"
    //     }
    // ];

    // const informationLinks = [
    //     {
    //         name: "Return & Exchange Policy",
    //         link: "/policies/returnAndExchangePolicy"
    //     },
    //     {
    //         name: "Term of Service",
    //         link: "/policies/termsOfService"
    //     },
    //     {
    //         name: "Refund Policy",
    //         link: "/policies/refundPolicy"
    //     },
    //     {
    //         name: "Privacy Policy",
    //         link: "/policies/privacyPolicy"
    //     },
    //     {
    //         name: "Terms And Conditions",
    //         link: "/policies/termsAndConditions"
    //     }
    // ];

    return (
        <div className="main-footer">
            <Container className="footer-container">
                <div className="footer-sec-1">
                    <h1 className="mb-1">About Us</h1>
                    <p>
                        Nex Era is an online apparel brand launched in 2021, dedicated to bringing a perfect blend of youthful, elegant, and trendy styles at highly affordable prices. At Nex Era, we strive to offer top-quality craftsmanship and fashionable designs that fit every budget.
                    </p>
                </div>
                {/* <div className="footer-sec-2">
                    <div className="footer-accordion-div">
                        <Accordion className="footer-accordion">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>Company</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                {companyLinks.map((v, i) => {
                                    return (
                                        <Link key={i} to={`${v.link}`} className="footer-links">
                                            {v.name}
                                        </Link>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="footer-accordion">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>Help</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                {helpLinks.map((v, i) => {
                                    return (
                                        <Link key={i} to={`${v.link}`} className="footer-links">
                                            {v.name}
                                        </Link>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="footer-accordion">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>Contact Info</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                <Link to={''} className="footer-links footer-links-contact">
                                    Sanasafinaz UAN
                                </Link>
                                <Link to={''} className="footer-links footer-links-contact">
                                    PAKISTAN:
                                </Link>
                                <Link to={'tel:'} className="footer-links footer-links-contact">
                                    021-123-4567-891
                                </Link>
                                <Link to={''} className="footer-links footer-links-contact">
                                    021-12345678
                                </Link>
                                <br />
                                <Link to={''} className="footer-links footer-links-contact">
                                    Email
                                </Link>
                                <Link to={'mailto:sales@gmail.com'} className="footer-links footer-links-contact">
                                    sales@gmail.com
                                </Link>
                                <Link to={'mailto:bridals@gmail.com'} className="footer-links footer-links-contact">
                                    bridals@gmail.com
                                </Link>
                                <Link to={''} className="footer-links footer-links-contact">
                                    Sanasafinaz UAN
                                </Link>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="footer-accordion">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>Information</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                {informationLinks.map((v, i) => {
                                    return (
                                        <Link key={i} to={`${v.link}`} className="footer-links">
                                            {v.name}
                                        </Link>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div>
                        <p>Company</p>
                        {companyLinks.map((v, i) => {
                            return (
                                <Link key={i} to={`${v.link}`} className="footer-links">
                                    {v.name}
                                </Link>
                            )
                        })}
                    </div>
                    <div>
                        <p>Help</p>
                        {helpLinks.map((v, i) => {
                            return (
                                <Link key={i} to={`${v.link}`} className="footer-links">
                                    {v.name}
                                </Link>
                            )
                        })}
                    </div>
                    <div>
                        <p>Contact Info</p>
                        <Link to={''} className="footer-links footer-links-contact">
                            Sanasafinaz UAN
                        </Link>
                        <Link to={''} className="footer-links footer-links-contact">
                            PAKISTAN:
                        </Link>
                        <Link to={'tel:'} className="footer-links footer-links-contact">
                            021-123-4567-891
                        </Link>
                        <Link to={''} className="footer-links footer-links-contact">
                            021-12345678
                        </Link>
                        <br />
                        <Link to={''} className="footer-links footer-links-contact">
                            Email
                        </Link>
                        <Link to={'mailto:sales@gmail.com'} className="footer-links footer-links-contact">
                            sales@gmail.com
                        </Link>
                        <Link to={'mailto:bridals@gmail.com'} className="footer-links footer-links-contact">
                            bridals@gmail.com
                        </Link>
                    </div>
                    <div>
                        <p>Information</p>
                        {informationLinks.map((v, i) => {
                            return (
                                <Link key={i} to={`${v.link}`} className="footer-links">
                                    {v.name}
                                </Link>
                            )
                        })}
                    </div>
                </div> */}
                <div className="footer-sec-5">
                    <div className="social-links-div">
                        <Link to={"/"} target="_blank" className="social-link">
                            <Tooltip title="Follow on Facebook">
                                <FaFacebookF size={28} />
                            </Tooltip>
                        </Link>
                        <Link to={"/"} target="_blank" className="social-link instagram-link">
                            <Tooltip title="Follow on Instagram">
                                <FaInstagram size={28} />
                            </Tooltip>
                        </Link>
                        <Link to={"mailto:"} target="_blank" className="social-link mail-link">
                            <Tooltip title="Email">
                                <IoMdMail size={28} />
                            </Tooltip>
                        </Link>
                        <Link to={"/"} target="_blank" className="social-link phone-link">
                            <Tooltip title="Phone">
                                <FaPhoneAlt size={28} />
                            </Tooltip>
                        </Link>
                    </div>
                </div>
            </Container>
            <div className="footer-sec-4">
                <div className="footer-sec-4-container">
                    <span>© 2024 - Ayetec Solutions</span>
                </div>
            </div>
        </div>
    );
};

export default Footer;