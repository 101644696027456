import "./style.css";
import HeroVideo from "../../Assets/Home/Hero.mp4";

const Hero = () => {
    return (
        <div className="main-hero-div">
            <video loop muted autoPlay className="hero-video">
                <source src={HeroVideo} type="video/mp4" />
            </video>
        </div>
    );
};

export default Hero;